import { PageProps } from '@lp-src/utils/local-types';
import { Col, Grid, Row } from '@web/atomic';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import SvgImgSoniaDown from '@web/atomic/atm.svg-icon/img-sonia-down';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { getCanonicalUrl } from '@web/utils/url';
import * as React from 'react';
import AboutUsBelieveSection from './mol.about-us-believe-section/about-us-believe-section.component';
import { AboutUsHeroSection } from './mol.about-us-hero-section/about-us-hero-section.component';
import AboutUsTeamSection from './mol.about-us-team-section/about-us-team-section.component';
import AboutUsHcpSection from './mol.about-us-hcp-section/about-us-hcp-section.component';

export const AboutUs: React.FunctionComponent<PageProps> = (props) => {
  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Sobre nós | Vigilantes do Sono',
          description: 'Veja o time por trás da Sônia.',
        }}
      />

      <AboutUsHeroSection />
      <AboutUsBelieveSection />
      <AboutUsTeamSection />
      <AboutUsHcpSection />

      <section id="sonia-down">
        <Grid>
          <Row center={'xs'}>
            <Col xs={8} sm={5} md={4} lg={3}>
              <SvgImgSoniaDown />
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>
      </section>
    </IndexLayout>
  );
};
