import { EmojiTitleDescription } from '@lp-root/src/components/mol.emoji-title-description/emoji-text-vertical.component';
import { Col, FeaturedH2, Grid, Row, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import * as React from 'react';

const AboutUsBelieveSection: React.FunctionComponent = () => {
  return (
    <section id="we-believe">
      <Grid>
        <Row center="xs">
          <Col xs={12} md={8}>
            <FeaturedH2>Acreditamos que</FeaturedH2>
            <CardWrapper>
              <Row center={'xs'}>
                <Col xs={12} sm={6}>
                  <Separator />
                  {[
                    {
                      emoji: '😴',
                      description: (
                        <>
                          <strong>Todas as pessoas têm o direito de dormir melhor.</strong> Começar o dia bem faz toda a diferença para
                          vivermos vidas mais plenas.
                        </>
                      ),
                    },
                    {
                      emoji: '👨‍👩‍👧‍👦',
                      description: (
                        <>
                          O melhor caminho para dormir bem é através de uma <strong>solução saudável, sustentável e acessível</strong>.
                        </>
                      ),
                    },
                    {
                      emoji: '👩‍🔬',
                      description: (
                        <>
                          <strong>O poder da tecnologia e da validação científica</strong> disponibiliza o que existe de mais efetivo para
                          melhorar o sono.
                        </>
                      ),
                    },
                  ].map((item) => (
                    <EmojiTitleDescription key={item.emoji} {...item} />
                  ))}
                </Col>
              </Row>
            </CardWrapper>
          </Col>
        </Row>
      </Grid>
    </section>
  );
};

export default AboutUsBelieveSection;
