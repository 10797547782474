import { GatsbyButton } from '@components/atm.button/button.component';
import { PersonCollapseCard } from '@lp-root/src/components/mol.person-collapse-card/person-collapse-card';
import { Body, FaIcon, LightDivisor, Separator } from '@web/atomic';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

export interface TeamMemberCardProps {
  imgSrc: IGatsbyImageData | string;
  name: string;
  occupation: string;
  subOccupation?: string;
  description: string | JSX.Element;
  linkedinLink?: string;
}

export const TeamMemberCard: React.FunctionComponent<TeamMemberCardProps> = (props) => {
  const { imgSrc, name, occupation, subOccupation, description, linkedinLink } = props;
  return (
    <PersonCollapseCard
      title={name}
      description={
        <>
          <strong>{occupation}</strong> {subOccupation ? `e ${subOccupation}` : ''}
        </>
      }
      imgSrc={imgSrc}
      imgAlt={`Foto de ${name}`}
    >
      <Separator />
      <LightDivisor />
      <Separator />
      <Body>{description}</Body>
      <Separator />
      {linkedinLink && (
        <>
          <GatsbyButton kind="link" external to={linkedinLink} expanded>
            <FaIcon.Linkedin size="lg" />
            Ver LinkedIn
          </GatsbyButton>
          <Separator />
        </>
      )}
    </PersonCollapseCard>
  );
};
