import * as React from 'react';

import { Col, FeaturedH2, Grid, Row, Separator } from '@web/atomic';
import { graphql, useStaticQuery } from 'gatsby';
import { TeamMemberCard, TeamMemberCardProps } from '../mol.team-member-card/team-member-card.component';

import { Blob2SectionStyled } from '@lp-root/src/components/atm.background/background.styled';
import ExternalLink from '@web/atomic/atm.external-link/external-link.component';

const AboutUsTeamSection: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    {
      hashi: file(relativePath: { eq: "hashi.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      lucas: file(relativePath: { eq: "lucas.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      laura: file(relativePath: { eq: "laura2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      caro: file(relativePath: { eq: "caro.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      josimar: file(relativePath: { eq: "josimar.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      giovana: file(relativePath: { eq: "gi.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
      francieli: file(relativePath: { eq: "fran.png" }) {
        childImageSharp {
          gatsbyImageData(width: 136, layout: FIXED, placeholder: BLURRED)
        }
      }
    }
  `);

  const cards: TeamMemberCardProps[] = [
    {
      imgSrc: data.lucas.childImageSharp.gatsbyImageData,
      name: 'Lucas Figueiredo',
      occupation: 'CEO',
      subOccupation: 'amante da tecnologia',
      description: `O Lucas é um dos co-fundadores da Vigilantes do Sono. Depois de se graduar mestre em Design Thinking pela Escola Politécnica da USP e desenvolver soluções para algumas das maiores empresas de saúde do Brasil, descobriu que tinha muita gente que precisava de ajuda para dormir, e essa virou sua nova missão.`,
      linkedinLink: 'https://www.linkedin.com/in/lucas-baracas-figueiredo/',
    },
    {
      imgSrc: data.hashi.childImageSharp.gatsbyImageData,
      name: 'Guilherme Hashioka',
      occupation: 'CTO',
      subOccupation: 'viciado em sono',
      description: `O "Hashi" é um dos co-fundadores da Vigilantes do Sono. Depois de conseguir seu duplo-diploma pela Escola Politécnica da USP e École supérieure d'électricité, passou mais de 5 anos desenvolvendo soluções digitais nas áreas de saúde e e-commerce. Somando isso com seu vício pelo sono, nasceu a Vigilantes do Sono.`,
      linkedinLink: 'https://www.linkedin.com/in/guilherme-hashioka-b8875683/',
    },
    {
      imgSrc: data.laura.childImageSharp.gatsbyImageData,
      name: 'Laura Castro',
      occupation: 'CPO',
      subOccupation: 'especialista em sono',
      description: (
        <>
          A Laura é sócia da Vigilantes do Sono. É psicóloga (CRP: 06/83226), com vasta experiência em sono no Brasil e no exterior. Depois
          de mais de 15 anos atuando na área
          <ExternalLink to="https://www.ncbi.nlm.nih.gov/pubmed/?term=Castro%20LS%5BAuthor%5D&cauthor=true&cauthor_uid=23720241">
            , publicações relevantes
          </ExternalLink>{' '}
          e de fundar o<ExternalLink to="https://www.psisono.com.br/"> PsiSono</ExternalLink>, hoje está realizando seu sonho de ter uma
          startup para curar a insônia de milhões de pessoas.
        </>
      ),
      linkedinLink: 'https://www.linkedin.com/in/laurascastro',
    },
    {
      imgSrc: data.caro.childImageSharp.gatsbyImageData,
      name: 'Carolina Gómez',
      occupation: 'Customer Happiness',
      subOccupation: 'super vespertina',
      description: `A Carol estará sempre à disposição para te ajudar com qualquer dúvida ou para deixar o seu programa ainda mais personalizado para você.
      Depois de se graduar em engenharia no México e fazer um mestrado em ciência ambiental pela USP, contagiou-se com a vontade de ajudar as pessoas através da cura da insônia acessível para todos.`,
      linkedinLink: 'https://www.linkedin.com/in/carolina-g%C3%B3mez-p%C3%A9rez',
    },
    {
      imgSrc: data.josimar.childImageSharp.gatsbyImageData,
      name: 'Josimar E. C. Saire',
      occupation: 'Data Scientist',
      subOccupation: 'matutino',
      description: `Josimar é cientista de dados, com experiencia em vários projetos usando Inteligência Artificial. Atualmente está terminando seu doutorado na área na Universidade de São Paulo.
      Sempre pensando como contribuir na sociedade e criar um melhor futuro, hoje é parte de Vigilantes do Sono.`,
      linkedinLink: 'https://www.linkedin.com/in/josimarchire/',
    },
    {
      imgSrc: data.francieli.childImageSharp.gatsbyImageData,
      name: 'Francieli Ruiz',
      occupation: 'Pesquisadora',
      subOccupation: 'colaboradora',
      description: `Francieli é bióloga e pesquisadora colaboradora na Vigilantes do Sono. Formada pela UNESP, com doutorado pela UNIFESP e período sanduíche
      na Universidade de Harvard. Possui Pós-Doutorado pela UNIFESP, com foco na resposta à vacinação em trabalhadores por turno, e Pós-Doutorado em Ciências da Saúde pela USP,
      com ênfase em ritmos biológicos. Atuou recentemente como pesquisadora convidada da Universidade de Surrey – programa Newton
      International Fellowship da Academia Britanica de Medicina. Possui mais de 15 anos de experiência em pesquisas cientificas na área de Biologia e Medicina do Sono.`,
      linkedinLink: 'linkedin.com/in/francieli-s-ruiz-16917a17/',
    },
    {
      imgSrc: data.giovana.childImageSharp.gatsbyImageData,
      name: 'Giovana C. Lamonica',
      occupation: 'Trainee',
      subOccupation: 'conectora de propósitos',
      description: `Bióloga pela UFSCar, Giovana compõe o time de pesquisa e desenvolvimento da Vigilantes do Sono. Sempre visou atuar na área da saúde e hoje acredita que com a pesquisa no sono  pode realizar seu sonho de ajudar e cuidar de pessoas.`,
      linkedinLink: 'linkedin.com/in/giovana-contarelli-lamonica-297a2918b/',
    },
  ];

  return (
    <section id="meet-the-team">
      <Blob2SectionStyled>
        <Grid>
          <Row>
            <Col xs={12}>
              <FeaturedH2>Conheça o time</FeaturedH2>
            </Col>
          </Row>
          <Row center={'xs'}>
            {cards.map((item) => (
              <Col xs={12} sm={4} md={4} lg={4} key={item.name}>
                <TeamMemberCard {...item} />
                <Separator />
              </Col>
            ))}
          </Row>
        </Grid>
      </Blob2SectionStyled>
    </section>
  );
};

export default AboutUsTeamSection;
