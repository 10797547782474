import * as React from 'react';

const SvgImgSonia = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg viewBox="0 0 403 193" {...props}>
    <g transform="translate(-14 -5)" fill="none" fillRule="evenodd">
      <path d="M0 0h432v214.5H0z" />
      <ellipse fill="#ECD9B4" transform="rotate(1 34.824 104.3)" cx={34.824} cy={104.299} rx={9.9} ry={10.725} />
      <ellipse fill="#ECD9B4" transform="rotate(1 34.626 162.88)" cx={34.626} cy={162.88} rx={10.725} ry={8.25} />
      <path
        d="M16.633 106.457c.143-8.2 6.907-14.731 15.107-14.588 8.2.143 14.732 6.907 14.589 15.106-.143 8.2-6.907 14.732-15.107 14.59-8.2-.144-14.732-6.908-14.589-15.108zm9.942-2.301a9.075 9.075 0 1 0 18.147.316 9.075 9.075 0 0 0-18.147-.316zM14.858 160.885c.104-5.923 6.835-10.608 15.035-10.465 8.2.144 15.658 1.1 14.66 10.983-.997 9.884-6.834 10.607-15.034 10.464-8.2-.143-14.764-5.06-14.66-10.982zm11.52 1.85c-.072 4.101 3.563 7.49 8.119 7.569 4.555.08 8.307-3.18 8.378-7.28.072-4.1-3.564-7.488-8.119-7.568-4.555-.08-8.307 3.18-8.378 7.28z"
        fill="#B68160"
      />
      <path
        d="M46.473 103.684c23.747-13.592 43.08-30.902 58.001-51.931 22.381-31.543 67.073-42.529 81.386-44.8 14.313-2.271 46.21-1.714 63.704 6.154 0 0 15.182-3.936 41.88 5.774 26.7 9.71 56.257 48.042 73.444 73.553 17.186 25.512 34.681 33.38 42.943 41.088 8.262 7.707 15.42 30.522-.47 26.884 0 0-5.212 9.993-16.992 11.468-11.78 1.475-33.692 6.135-64.041 13.169-30.35 7.033-103.375 5.758-136.11 5.187-32.736-.571-70.537.45-101.432-9.334-20.597-6.522-36.764-10.294-48.5-11.316l1.063-60.943 3.43-4.142 1.694-.811z"
        fill="#B68160"
      />
      <path
        d="M63.444 131.207l.68.008.668.025.32.02c9.767.654 12.75 9.042 10.33 19.9l-.08.354c-1.756 7.553-6.199 10.961-12.323 11.03-2.205.024-4.504-.39-6.812-1.121a28.737 28.737 0 0 1-3.98-1.588c-8.175-3.993-11.31-9.145-10.629-15.748.575-5.582 5.97-9.821 13.633-11.814 1.296-.35 2.793-.634 4.24-.83.369-.05.717-.093 1.02-.124l.415-.039c.089-.007.168-.012.238-.016l.18-.006.1.002.083.007.533-.03c.7-.031 1.389-.039 2.063-.022z"
        fill="#ECD9B4"
        fillRule="nonzero"
      />
      <path
        d="M147.57 190.224c-.983 5.951 3.126 7.63 5.303 7.725 1.968-.018 7.91-.59 15.94-2.737 10.038-2.683 23.221-3.667 34.27-4.589 11.048-.921 20.704-5.026 27.093-9.273 6.39-4.248 13.034-7.547 17.934-7.333 4.898.214 10.179 1.85 7.648-3.568-2.532-5.419.218-9.201-1.946-17.257-2.164-8.057-18.121-70.574-21.282-76.332-3.16-5.758-3.819-14.217-6.11-22.747-2.291-8.53-4.517 1.207-13.049 3.488-8.531 2.28-26.343-.842-40.395 2.914-14.053 3.756-14.66 9.506-30.345 13.19-15.686 3.685-18.832 1.986-22.854 1.03-4.023-.957-5.019 1.34-1.23 7.44 3.79 6.097 10.901 62.608 19.222 71.558 8.32 8.95 11.03 29.051 9.802 36.49z"
        fill="#050D3D"
        fillRule="nonzero"
      />
      <path
        d="M123.044 75.538c1.01.206 2.156.368 3.59.416 4.314 14.058 7.382 24.922 9.207 32.602l.279 1.194c.182.791.364 1.595.573 2.529l1.424 6.408c1.497 6.663 2.236 9.534 3.571 13.88l.08.256c2.195 7.119 4.941 13.82 8.63 20.707l.486.9c6.202 11.354 7.841 25.8 4.973 43.304-1.35.149-2.364.21-2.984.215a5.201 5.201 0 0 1-.4-.033c2.883-16.925 1.443-30.736-4.261-41.467l-.228-.423c-4.045-7.406-7.021-14.585-9.376-22.22l-.466-1.53c-1.11-3.7-1.847-6.623-3.155-12.431l-1.672-7.51c-.146-.653-.282-1.248-.417-1.836l-.368-1.562c-1.851-7.725-4.955-18.67-9.308-32.823z"
        fillOpacity={0.3}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M145.898 92.612l.451 2.12c.527 2.533 1.682 8.163 2.03 9.817l.537 2.522c.765 3.55 1.494 6.72 2.28 9.879 2.503 10.048 5.385 19.05 9.026 27.598.73 1.711 1.486 3.395 2.271 5.052l.594 1.237c6.068 12.468 8.337 27.173 6.832 44.09l-.155.038-.95.247c-.78.208-1.539.402-2.276.581 1.64-16.574-.412-30.872-6.132-42.919l-.26-.538a141.123 141.123 0 0 1-2.935-6.444c-3.718-8.73-6.654-17.9-9.197-28.11l-.363-1.478c-.72-2.96-1.402-5.975-2.12-9.335l-.787-3.751c-.6-2.9-1.53-7.43-1.796-8.687a162.039 162.039 0 0 0-5.49-19.712c1.014-.195 2.095-.42 3.25-.675a165.062 165.062 0 0 1 5.19 18.468z"
        fillOpacity={0.5}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M159.663 92.864l.314 1.41c1.124 5.081 6.088 29.303 7.032 32.986l.028.11c.752 2.84 1.715 5.363 3.162 8.453l.535 1.122c.754 1.549 2.337 4.736 2.5 5.068 1.17 2.398 2.092 4.414 2.984 6.586 2.055 5.003 3.737 10.384 5.166 16.886 2.32 10.567 2.825 19.603 1.484 27.107-1.176.154-2.341.317-3.492.492 1.512-7.203 1.108-16.162-1.245-26.872-1.386-6.31-3.01-11.505-4.99-16.327a101.307 101.307 0 0 0-2.616-5.81l-2.938-5.973-.114-.237c-1.587-3.328-2.663-6.046-3.5-9.06l-.154-.569c-.897-3.39-5.977-28.182-7.093-33.23l-.455-2.04c-2.034-9.034-3.909-16.265-5.706-21.73a42.195 42.195 0 0 0 3.055-1.381c1.917 5.764 3.893 13.403 6.043 23.009z"
        fillOpacity={0.3}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M172.183 87.697l.194 1.742c.187 1.72.364 3.47.562 5.528l.888 9.452.182 1.847c.326 3.23.612 5.629.924 7.672.701 4.579 1.98 8.688 4.102 13.614l.282.647c.858 1.954 5.08 10.828 6.251 13.49 2.299 5.222 4.11 10.144 5.654 15.651a143.19 143.19 0 0 1 5.265 33.837c-1.127.096-2.266.197-3.41.302a139.928 139.928 0 0 0-5.152-33.264c-1.405-5.014-3.04-9.528-5.083-14.265l-.414-.951c-1.145-2.602-5.368-11.477-6.253-13.491-2.398-5.46-3.842-9.99-4.622-15.081l-.13-.882c-.33-2.307-.636-5.057-.999-8.817l-.837-8.931a485.285 485.285 0 0 0-.517-5.153l-.092-.857c-1.314-12.092-2.979-20.572-5.376-25.682.949-.497 1.963-.984 3.077-1.456 2.531 5.328 4.181 13.501 5.504 25.048z"
        fillOpacity={0.5}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M184.774 79.511l1.03 8.397c1.33 10.714 1.896 14.344 3.183 20.47l.264 1.245c2.057 9.619 3.734 16.339 5.686 23.037l.488 1.655.348 1.16.61 2.001c.682 2.217 2.271 7.344 2.797 9.073 1.204 3.95 2.302 7.699 3.474 11.9l.295 1.06c3.323 11.995 5.08 22.14 5.258 30.452-1.063.186-2.141.344-3.235.473-.12-7.875-1.76-17.628-4.933-29.24l-.206-.748a508.046 508.046 0 0 0-3.154-10.926l-.824-2.713c-.775-2.526-2.428-7.857-2.805-9.093l-.55-1.818-.343-1.153-.167-.565c-1.834-6.248-3.417-12.448-5.261-20.85l-.644-2.97c-1.636-7.654-2.154-11.03-3.883-25.141l-.714-5.829c-1.13-9.02-2.187-15.156-3.652-19.894 1.08-.176 2.174-.32 3.276-.439 1.472 4.982 2.53 11.323 3.662 20.456z"
        fillOpacity={0.3}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M201.542 92.595l.039.682c.579 11.018 1.926 19.413 4.411 28.435l.34 1.216c.889 3.124 1.927 6.407 3.369 10.71l5.163 15.241.546 1.637c4.435 13.401 6.956 25.007 7.552 34.832-1.042.48-2.125.942-3.246 1.38-.42-9.34-2.723-20.516-6.92-33.516l-.78-2.376-5.262-15.536-.38-1.135c-1.484-4.448-2.54-7.826-3.46-11.126l-.114-.412c-2.48-9.005-3.86-17.422-4.479-28.234l-.05-.933c-.675-12.82-4.31-25.773-8.873-34.91 1.228-.029 2.449-.042 3.656-.046 4.426 9.354 7.744 21.748 8.488 34.091z"
        fillOpacity={0.5}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M217.515 90.841l1.27 9.07.256 1.769c2.24 15.175 4.85 26.423 10.435 42.718l.658 1.903c4.356 12.497 6.498 22.9 6.392 31.24a63.987 63.987 0 0 0-3.463 1.97c.501-7.867-1.379-18.144-5.68-30.796l-1.116-3.235c-5.655-16.498-8.31-27.94-10.577-43.303l-.472-3.287-.956-6.856-.189-1.324c-1.913-13.239-4.36-24.874-6.888-32.29a42.844 42.844 0 0 0 3.479-.3c2.654 7.84 4.931 19.245 6.85 32.721z"
        fillOpacity={0.3}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M231.375 101.038l.215.58c2.525 6.808 3.49 12.397 4.739 24.533l.236 2.293c1.049 10.038 1.798 14.716 3.538 20.55a294.536 294.536 0 0 0 8.717 25.063c-.236-.016-.473-.03-.71-.04-.897-.04-1.853.04-2.855.22a297.728 297.728 0 0 1-8.395-24.286l-.283-.97c-1.648-5.763-2.392-10.678-3.435-20.748l-.355-3.434c-1.12-10.51-2.06-15.692-4.254-21.699l-.554-1.506c-5.221-14.293-10.326-32.111-12.63-44.692a16.228 16.228 0 0 0 3.103-1.831c2.16 12.466 7.517 31.278 12.923 45.967z"
        fillOpacity={0.5}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M60.36 147.706a4.13 4.13 0 0 1-.099.305c1.967.504 3.419.628 4.323.406 0-.117 0-.235-.003-.353-.022-.803-.156-1.502-.336-1.932l-.055-.121a1.65 1.65 0 0 1 2.948-1.484c.449.892.705 2.115.742 3.447.055 2.017-.392 3.93-1.28 4.965a1.65 1.65 0 0 1-2.602-2.023l.098-.126c.094-.11.192-.345.275-.665-1.27.151-2.935-.073-5.017-.647-1.97 2.142-5.91 2.631-7.443 1.806-3.03-1.63-.365-7.617 1.92-9.132 2.285-1.516 7.534 1.94 6.528 5.554z"
        fill="#734B23"
      />
      <path
        d="M266.196 23.526c2.824 6.931-.545 11.366-8.305 11.231-1.411-.025-2.987-.3-5.09-.847l-.722-.194c-1.526-.423-5.606-1.624-6.348-1.833-8.322-2.346-15.06-3.317-23.014-2.698-14.77 1.149-21.166 3.916-35.692 13.928a1.65 1.65 0 0 1-1.873-2.717l1.246-.854c14.113-9.626 21.056-12.48 36.063-13.647 8.13-.633 15.042.305 23.332 2.58l1.401.395c1.45.42 4.756 1.39 5.897 1.702l.136.037c2.081.558 3.545.828 4.722.849 5.473.095 7.088-2.03 5.19-6.687-1.128-2.768-5.678-6.723-13.567-11.665.126-.032 1.85-.456 5.045-.668 6.482 4.354 10.323 8.006 11.579 11.088zM343.188 149.778c12.274.723 23.43 1.682 33.471 2.876 9.935 1.182 20.635 3.853 32.103 8.011a16.093 16.093 0 0 1-1.4-.26s-.576 1.104-1.778 2.633c-10.464-3.66-20.236-6.027-29.315-7.107-9.972-1.186-21.063-2.14-33.275-2.859a1.65 1.65 0 1 1 .194-3.294z"
        fill="#050D3D"
      />
      <path
        d="M129.072 188.312c23.729 1.547 41.337-2.677 52.824-12.672 17.23-14.993 19.193-30.252 15.89-35.407-3.304-5.156-7.604-1.832-9.375 2.385-1.77 4.217-12.215 19.328-33.39 17.26-14.116-1.38-21.7-4.344-22.752-8.894"
        fill="#B68160"
      />
      <path
        d="M129.38 148.08a1.65 1.65 0 0 1 1.935 1.097l.04.145c.282 1.241 3.754 2.952 10.093 5.093a1.65 1.65 0 1 1-1.055 3.126l-1.277-.436c-7.144-2.473-10.326-4.18-10.979-7.051a1.65 1.65 0 0 1 1.243-1.975z"
        fill="#050D3D"
        fillRule="nonzero"
      />
      <path
        d="M19.398 142.697c.842 19.244 11.488 26.792 22.016 26.901 3.477.036 13.976.487 14.133-8.49.046-2.643-.783-5.3-2.488-7.974-1.13-2.147-1.677-4.213-1.643-6.197.053-3.016 1.53-4.984 3.585-6.866 6.243-4.417 8.132-12.315 7.38-19.377-1.004-9.428-8.999-16.896-18.575-17.063a17.702 17.702 0 0 0-.497-.001c-.118-.005-.236-.012-.355-.014-14.416-.255-24.397 19.837-23.556 39.081z"
        fill="#1D1841"
      />
    </g>
  </svg>
);

export default SvgImgSonia;
