import { Query } from '@lp-root/src/data/graphql/local-graphql-types';
import { graphql } from 'gatsby';
import * as React from 'react';
import { AboutUs } from '../modules/about-us/about-us';
import { PageProps } from '../utils/local-types';

const AboutUsPage: React.FunctionComponent<PageProps<unknown, Query>> = (props) => {
  return <AboutUs {...props} />;
};

export default AboutUsPage;

export const query = graphql`
  query AboutUsPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
