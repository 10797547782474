import { GrayColor, LightColor, FontSize } from '@web/atomic';
import styled, { keyframes } from 'styled-components';

export const AboutUsSectionStyled = styled.section`
  background-color: ${LightColor.Primary};
  position: relative;
  overflow: hidden;
  padding: 0;
`;

export const AboutUsContentStyled = styled.div`
  max-width: 480px;
  margin: auto;
`;

export const AboutUsSoniaStyled = styled.div`
  position: absolute;
  top: -15%;
  z-index: 2;
  width: 100%;
`;

export const zzzAnimation = [
  'none',
  keyframes`
    0%   {opacity: 0;}
    25%  {opacity: 0;}
    33%  {opacity: 1; transform: scale(0.8) rotate(2deg);}
    100%  {opacity: 1; transform: scale(0.8) rotate(2deg);}
  `,
  keyframes`
    0%   {opacity: 0;}
    58%  {opacity: 0;}
    66%  {opacity: 1; transform: scale(0.6) rotate(-2deg);}
    100%  {opacity: 1; transform: scale(0.6) rotate(-2deg);}
  `,
];

const color = GrayColor.GrayDark;
export const AboutUsSoniaZZZStyled = styled.span<{ index }>`
  display: block;
  position: absolute;
  bottom: ${(props) => 60 + props.index * 13}%;
  left: ${(props) => 5 + props.index * 6}%;
  color: white;
  /* https://stackoverflow.com/a/13427256/3670829 */
  text-shadow: 2px 0 0 ${color}, -2px 0 0 ${color}, 0 2px 0 ${color}, 0 -2px 0 ${color}, 1px 1px ${color}, -1px -1px 0 ${color},
    1px -1px 0 ${color}, -1px 1px 0 ${color};
  animation-name: ${(props) => zzzAnimation[props.index]};
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: normal;
  font-size: ${FontSize.XSmall};
  @media all and (min-width: 32em) {
    font-size: ${FontSize.Medium};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const blob3Path = require('@lp-root/src/assets/images/blob3.svg').default;
export const SoniaBkgSectionStyled = styled.section`
  position: relative;
  &:before {
    content: '';
    display: block;
    background-image: url(${blob3Path});
    background-size: cover;
    width: 100%;
    padding-top: 13.75%;
    top: 13%;
  }
  &:after {
    content: '';
    display: block;
    background: ${GrayColor.White};
    width: 100%;
    top: 67%;
    padding-top: 19%;
    @media all and (min-width: 48em) {
      padding-top: 8%;
    }
    @media all and (min-width: 64em) {
      padding-top: 6%;
    }
    @media all and (min-width: 76em) {
      padding-top: 3%;
    }
  }
`;
