import { Blob2SectionStyled } from '@lp-root/src/components/atm.background/background.styled';
import { Col, FeaturedH2, Grid, Row, Separator } from '@web/atomic';
import * as React from 'react';
import { allProfessionalsJSON } from '../../health-carer/health-carers/health-carers';
import { TeamMemberCard } from '../mol.team-member-card/team-member-card.component';

const AboutUsHcpSection: React.FunctionComponent = () => {
  const hcpToShow = ['Dalva Poyares', 'Fátima Cintra', 'Helena Hachul'];

  const cards = allProfessionalsJSON.filter((hcp) => hcpToShow.includes(hcp.name));

  if (cards.length !== hcpToShow.length) {
    throw Error('AboutUsHcpSection: invalid config');
  }

  return (
    <section id="meet-the-team">
      <Blob2SectionStyled>
        <Grid>
          <Row>
            <Col xs={12}>
              <FeaturedH2>Conselho clínico-científico</FeaturedH2>
            </Col>
          </Row>
          <Row center={'xs'}>
            {cards.map((item) => (
              <Col xs={12} sm={6} md={4} lg={4} key={item.name}>
                <TeamMemberCard
                  imgSrc={item.imgSrc}
                  name={item.name}
                  occupation={item.specialty}
                  description={<span dangerouslySetInnerHTML={{ __html: item.description }} />}
                  linkedinLink={item.linkedinLink}
                />
                <Separator />
              </Col>
            ))}
          </Row>
        </Grid>
      </Blob2SectionStyled>
    </section>
  );
};

export default AboutUsHcpSection;
