import { Col, Grid, HDisplay, Row, Separator } from '@web/atomic';

import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import SvgImgSonia from '@web/atomic/atm.svg-icon/img-sonia';
import * as React from 'react';
import {
  AboutUsContentStyled,
  AboutUsSectionStyled,
  AboutUsSoniaStyled,
  SoniaBkgSectionStyled,
  AboutUsSoniaZZZStyled,
} from './about-us-hero-section.styled';

export const AboutUsHeroSection: React.FunctionComponent = () => {
  return (
    <AboutUsSectionStyled id={'inicio'}>
      <Grid>
        <LargeSeparatorRow />
        <Row middle={'xs'} center={'xs'} mt mb>
          <Col xs={12} sm={7} md={6}>
            <AboutUsContentStyled>
              <HDisplay kind="light">Nossa missão é ajudar as pessoas a dormir melhor para que possam viver plenamente!</HDisplay>
              <Separator />
            </AboutUsContentStyled>
          </Col>
        </Row>
        <LargeSeparatorRow />
      </Grid>
      <SoniaBkgSectionStyled>
        <AboutUsSoniaStyled>
          <Grid>
            <Row center={'xs'}>
              <Col xs={10} sm={7} md={6} lg={5}>
                <ForcedFade show={true} delay={'.5s'}>
                  <SvgImgSonia />
                  <AboutUsSoniaZZZStyled index={0}>Z</AboutUsSoniaZZZStyled>
                  <AboutUsSoniaZZZStyled index={1}>Z</AboutUsSoniaZZZStyled>
                  <AboutUsSoniaZZZStyled index={2}>Z</AboutUsSoniaZZZStyled>
                </ForcedFade>
                <Separator />
              </Col>
            </Row>
          </Grid>
        </AboutUsSoniaStyled>
      </SoniaBkgSectionStyled>
    </AboutUsSectionStyled>
  );
};
